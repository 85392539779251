<template>
  <v-btn
    class="button"
    data-cy="button"
    data-jest="button"
    min-height="3rem"
    size="large"
    :block="fill"
    :class="[
      { 'button--secondary': secondary },
      { 'button--rounded': rounded },
    ]"
    :color="buttonColor"
    :min-width="fill ? undefined : sizeLarge ? '11.75rem' : '8.125rem'"
    :variant="secondary && !colors ? 'text' : 'flat'"
    v-bind="$attrs"
    @click="buttonClick"
  >
    <slot />
    <!-- Enables forwarding slots to v-btn. See https://stackoverflow.com/a/72721695/2152511 -->
    <template
      v-for="(_, slot) in $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { type Colors, softBlue } from "@/styles/colors";
import type { SignupState } from "@/store/signup/types";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: undefined,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    sizeLarge: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  data: () => ({
    softBlue,
  }),
  computed: {
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    buttonColor(): string | undefined {
      if (this.colors) {
        return this.secondary ? this.colors.secondary : this.colors.accent;
      }

      if (this.secondary) {
        return undefined;
      }

      return this.color ? this.color : softBlue;
    },
  },
  methods: {
    buttonClick(event: Event) {
      // Emit event for Vuetify activator functions (e.g., v-menu).
      this.$emit("click", event);
    },
  },
});
</script>

<style lang="scss" scoped>
.button {
  font-family: $heading-font-family;
  text-transform: none;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0;
  box-shadow: 0 0.1875rem 0.75rem #00000014;
  border-radius: 0.5rem;
  transition: background-color 0.1s ease-in-out;

  &--rounded {
    border-radius: 1.5rem;
  }

  &--secondary {
    color: rgba($nearly-black, 0.8);
  }

  &:before {
    background: transparent;
    transition: all 0.1s ease-in-out;
  }

  &:focus-visible {
    box-shadow:
      0 0 0.0625rem 0.0625rem $aqua,
      0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
  }
}
</style>
