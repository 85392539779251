import { FeeType, Market } from "@/clients";

const managementFeeConfig = {
  [FeeType.OriginalFees]: {
    [Market.Standard]: 0.35,
    [Market.Sweden]: 0.35,
    [Market.Europe]: 0.35,
    [Market.Us]: 0.35,
    [Market.SwedenOld]: 0.35,
  },
  [FeeType.SwedenPortfolioFees]: {
    [Market.Standard]: 0.47,
    [Market.Sweden]: 0.35,
    [Market.Europe]: 0.47,
    [Market.Us]: 0.47,
    [Market.SwedenOld]: 0.35,
  },
  [FeeType.Q22024Fees]: {
    [Market.Standard]: 0.55,
    [Market.Sweden]: 0.4,
    [Market.Europe]: 0.59,
    [Market.Us]: 0.59,
    [Market.SwedenOld]: 0.4,
  },
};

const transactionFeeConfig = {
  [FeeType.OriginalFees]: 0.1,
  [FeeType.SwedenPortfolioFees]: 0.22,
  [FeeType.Q22024Fees]: 0.25,
};

const fxFeeConfig = {
  [FeeType.OriginalFees]: 0,
  [FeeType.SwedenPortfolioFees]: 0,
  [FeeType.Q22024Fees]: 0.25,
};

const idunInsuranceFee = 0.2;

export function getIdunInsuranceFee() {
  return idunInsuranceFee.toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getManagementFeeForMarketFocus(marketFocus: Market | undefined): string {
  if (marketFocus !== undefined) {
    return managementFeeConfig[FeeType.Q22024Fees][marketFocus].toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getManagementFeeForMarketFocusWithoutVat(marketFocus: Market | undefined): string {
  if (marketFocus !== undefined) {
    return (managementFeeConfig[FeeType.Q22024Fees][marketFocus] / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getTransactionFeeWithoutVat(): string {
  return (transactionFeeConfig[FeeType.Q22024Fees] / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getTransactionFee(): string {
  return (transactionFeeConfig[FeeType.Q22024Fees]).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getFxFee(): string {
  return (fxFeeConfig[FeeType.Q22024Fees]).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getFxFeeWithoutVat(): string {
  return (fxFeeConfig[FeeType.Q22024Fees] / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getPriceGroup(): string {
  return "pricegroup_C";
}
