<template>
  <MarketFocus
    :heading="$t('marketHeading')"
    :market-focus="marketFocus"
    :management-fee="managementFee"
    @change="(event) => (marketFocus = event)"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { Market } from "@/clients";
import MarketFocus from "@/views/common-signup/questions/preferences/market-focus.vue";
import { SignupMutation } from "@/store/signup/mutations";
import { getManagementFeeForMarketFocus } from "@/utils/fees";

export default defineComponent({
  components: { MarketFocus },
  computed: {
    marketFocus: {
      set(market: Market): void {
        this.$store.commit(SignupMutation.setMarketFocus, market);
      },
      get(): Market {
        return this.$store.state.signupStore.marketFocus;
      },
    },
    managementFee(): string {
      return getManagementFeeForMarketFocus(
        this.$store.state.signupStore.marketFocus,
      );
    },
  },
});
</script>
