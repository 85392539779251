<template>
  <div class="choose">
    <template
      v-if="
        legalEntities !== undefined
          && (legalEntities.length > 1 || notApprovedLegalEntitySummaries.length > 0)
      "
    >
      <div class="choose__logo-container">
        <MainLogo :tied-agent="tiedAgent" />
      </div>
      <p class="choose__title">
        {{ $t("choose-legal-entity.choose") }}
      </p>
      <div
        v-for="(legalEntity, index) in orderedLegalEntitiesAndWaitingLegalEntities"
        :key="index"
        class="choose__button-wrapper"
      >
        <CommonButton
          class="choose__button"
          fill
          :color="primaryColor"
          :data-cy="`button_${index}`"
          :disabled="!legalEntity.passedIsApprovedCheck"
          @click="selectLegalEntity(legalEntity)"
        >
          {{
            legalEntity.isNaturalPerson
              ? $t("choose-legal-entity.yourPrivateAccount")
              : legalEntity.firstName
          }}
        </CommonButton>
        <div
          v-if="!legalEntity.passedIsApprovedCheck"
          style="font-size: 0.8rem; margin-top: -1rem"
        >
          {{ $t("choose-legal-entity.waitingApproval") }}
        </div>
      </div>
      <a
        class="choose__link"
        @click="cancel"
      >{{ switchAccount ? $t("cancel") : $t("cancelLogin") }}
      </a>
    </template>
    <LoggedOutDialog
      v-if="showLoggedOutDialog"
      :model-value="showLoggedOutDialog"
      @confirm="signOut"
    />
    <LoadingSpinnerOverlay :model-value="showOverlay" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import CommonButton from "@/components/button.vue";
import type {
  ApplicationUser,
  LegalEntity,
  LegalEntitySummary,
  LegalEntityUser,
  TiedAgent,
} from "@/clients";
import {
  TiedAgentsClient,
} from "@/clients";
import { UserMutation } from "@/store/user/mutations";
import { UserAction } from "@/store/user/action-definitions";
import { NavigationAction } from "@/store/navigation/actions";
import { CURRENT_LEGAL_ENTITY } from "@/config/general";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import clearStore from "@/utils/clear-store";
import MainLogo from "@/components/main-logo.vue";
import { baseUrl } from "@/clients/config";
import store from "@/store";
import LoggedOutDialog from "@/components/logged-out-dialog.vue";

const tiedAgentClient = new TiedAgentsClient(baseUrl);

export default defineComponent({
  components: { CommonButton, LoadingSpinnerOverlay, MainLogo, LoggedOutDialog },
  data: () => ({
    showOverlay: true,
    switchAccount: false,
    logoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href,
  }),
  computed: {
    ...mapGetters(["legalEntities", "isLoggedIn"]),
    showLoggedOutDialog(): boolean {
      const { isLoggedIn } = this.$store.getters;
      if (isLoggedIn(this.$store.state) === false) {
        return true;
      }
      return false;
    },
    primaryColor(): string {
      return this.$store.state.userStore.primaryColor;
    },
    tiedAgent(): TiedAgent | undefined {
      return this.$store.state.userStore.tiedAgent;
    },
    notApprovedLegalEntitySummaries(): LegalEntitySummary[] {
      const waitingLegalEntities
        = this.$store.state.userStore.currentUser?.notApprovedLegalEntitySummaries;
      if (waitingLegalEntities) {
        return waitingLegalEntities;
      }
      return [];
    },
    legalEntitiesAndNotApprovedLegalEntities(): LegalEntity[] {
      return [...this.legalEntities, ...this.notApprovedLegalEntitySummaries];
    },
    user(): ApplicationUser | undefined {
      return this.$store.state.userStore.currentUser;
    },
    orderedLegalEntitiesAndWaitingLegalEntities(): LegalEntity[] {
      return [...this.legalEntitiesAndNotApprovedLegalEntities].sort((a: any, b: any) => {
        // Order by:
        // 1. Passed isapproved check
        // 2. Is natural person
        // 3. Creation date
        if (a.isNaturalPerson > b.isNaturalPerson) {
          if ((a.passedIsApprovedCheck ? 1 : 0) > (b.passedIsApprovedCheck ? 1 : 0)) {
            return -1;
          }
          return -1;
        }
        return a.createdAt < b.createdAt ? -1 : 1;
      });
    },
  },
  async created() {
    const { tiedAgent } = this.$route.query;

    if (tiedAgent && store.state.userStore.tiedAgent === undefined) {
      const tiedAgentInfo = await tiedAgentClient.getTiedAgent(tiedAgent as string);
      this.$store.commit(UserMutation.setTiedAgent, tiedAgentInfo);
      this.$store.commit(UserMutation.setPrimaryColor, tiedAgentInfo?.color as string);
    }

    this.switchAccount = this.$router.currentRoute.value.query?.switchAccount === "true";

    if (!this.user && this.isLoggedIn) {
      await this.$store.dispatch(UserAction.loadCurrentUser);
    }

    this.$store.commit(UserMutation.setSafeToReloadApp, true);

    if (
      this.legalEntities
      && this.legalEntities.length === 1
      && this.notApprovedLegalEntitySummaries.length === 0
    ) {
      this.selectLegalEntity(this.legalEntities[0]);
    } else {
      this.showOverlay = false;
    }
  },

  methods: {
    async selectLegalEntity(legalEntity: LegalEntity) {
      try {
        this.$store.commit(UserMutation.setInfoBanners, []);
        this.$store.commit(
          UserMutation.setLegalEntity,
          this.$store.state.userStore.currentUser?.legalEntityUsers.find(
            (leu: LegalEntityUser) =>
              leu.legalEntity && leu.legalEntity.brickId === legalEntity.brickId,
          )?.legalEntity,
        );

        sessionStorage.setItem(CURRENT_LEGAL_ENTITY, legalEntity.brickId);
        await this.$store.dispatch(NavigationAction.loadPortfolios, { forceReload: true });
        // Overview is a bit more sales-y so likely beneficial to put user here even if they
        // only have one portfolio. Maybe?
        this.$router.replace({ name: "overview" });
      } catch (error: any) {
        sessionStorage.removeItem("token");
        clearStore();
        this.$router.push({ name: "login" });
      }
    },
    cancel() {
      if (this.switchAccount) {
        this.$router.go(-1);
      } else {
        sessionStorage.removeItem("token");
        clearStore();
        this.$router.push({ name: "login" });
      }
    },
    signOut() {
      this.$store.dispatch(UserAction.signOut);
    },
  },
});
</script>

<style lang="scss" scoped>
.choose {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 2.5625rem;
  align-items: center;
  text-align: center;
  height: 100%;

  @include small-up {
    padding-top: 5rem;
  }

  &__logo-container {
    width: 100%;
    text-align: center;
    height: 4.0625rem;
  }

  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }

  &__title {
    margin: 1rem 0;
    font-size: 1rem;
    font-family: $heading-font-family;
  }

  &__button-wrapper {
    max-width: 16rem;
    width: 100%;
    padding-top: 1rem;
  }

  &__link {
    position: absolute;
    bottom: 4rem;
    right: 0;
    left: 0;
    @include medium-up {
      bottom: 10rem;
    }
  }
}
</style>
